import { makeStyles, createStyles } from "@mui/styles";
import IlustrationHero from "~/assets/ilustration/hero/hero-1.png";

export const useStylesHero = makeStyles(() =>
  createStyles({
    rootHero: {
      height: "100vh",
      paddingTop: "5rem",
    },
    heroRight: {
      backgroundImage: `url(${IlustrationHero})`,
      position: "absolute",
      width: "100%",
      height: "110%",
      right: 0,
      top: 70,
      zIndex: 999,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  })
);
