import IlustrationProdukOne from "~/assets/ilustration/produk/produk-1.png";
import IlustrationProdukTwo from "~/assets/ilustration/produk/produk-2.png";
import IlustrationProdukThree from "~/assets/ilustration/produk/produk-3.png";
import IlustrationProdukFour from "~/assets/ilustration/produk/produk-4.png";
import IlustrationProdukFive from "~/assets/ilustration/produk/produk-5.png";
import IlustrationProdukSix from "~/assets/ilustration/produk/produk-6.png";
import IlustrationProdukSeven from "~/assets/ilustration/produk/produk-7.png";
import IlustrationProdukEight from "~/assets/ilustration/produk/produk-8.png";
import IlustrationProdukNine from "~/assets/ilustration/produk/produk-9.png";
import IlustrationProdukTen from "~/assets/ilustration/produk/produk-10.png";
import IlustrationProdukEleven from "~/assets/ilustration/produk/produk-11.png";
import IlustrationProdukTweleve from "~/assets/ilustration/produk/produk-12.png";

export const PRODUK_DATAS = [
  {
    image: IlustrationProdukOne,
    title: "Pemetaan Desa",
    description: "Survei Udara Menggunakan Drone, Temu Kenali Potensi Desa",
  },
  {
    image: IlustrationProdukTwo,
    title: "Sistem Administrasi Desa",
    description: "Kelola Data Administrasi dan Aset Desa",
  },
  {
    image: IlustrationProdukThree,
    title: "Sistem Informasi Pelayanan Publik",
    description: "Layanan Publik Masyarakat Desa",
  },
  {
    image: IlustrationProdukFour,
    title: "Sistem Informasi Keuangan Desa",
    description: "Kelola Keuangan Desa dengan Mudah, Aktual dan Terintegrasi",
  },
  {
    image: IlustrationProdukFive,
    title: "Sistem Informasi Geospasial",
    description: "Kelola Data Geospasial Desa, Bangun Peta Desa Digital",
  },
  {
    image: IlustrationProdukSix,
    title: "Sistem Informasi Bumdes",
    description: "Kelola Berbagai Macam Usaha Desa dalam Satu Dashboard",
  },
  {
    image: IlustrationProdukSeven,
    title: "Website Desa",
    description: "Etalase Desa dan Media Komunikasi",
  },
  {
    image: IlustrationProdukEight,
    title: "Aplikasi Mobile",
    description: "Akses Layanan Publik, Akses Potensi Desa",
  },
  {
    image: IlustrationProdukNine,
    title: "Corporate Farming",
    description: "Kelola Potensi Pertanian dengan Profesional",
  },
  {
    image: IlustrationProdukTen,
    title: "Masterplan Desa",
    description: "Konsultasi, Perencanaan, Desain dan Visualisasi",
  },
  {
    image: IlustrationProdukEleven,
    title: "Video Profil Desa",
    description: "Media Visual Promosi Desa",
  },
  {
    image: IlustrationProdukTweleve,
    title: "Command Center Desa",
    description: "Pusat Kendali Desa Berbasis Teknologi",
  },
];
