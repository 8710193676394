/**
 * CLIENT LOGO
 */

import LogoDepputi from "~/assets/ilustration/klien/client-logo/aplikasi-dan-website/deputi.png";
import LogoDesaCerdas from "~/assets/ilustration/klien/client-logo/aplikasi-dan-website/desacerdas.png";
import LogoDilan from "~/assets/ilustration/klien/client-logo/aplikasi-dan-website/dilan.png";
import LogoDesaPintar from "~/assets/ilustration/klien/client-logo/masterplan/desapintar.png";

import LogoKabGorontalo from "~/assets/ilustration/klien/client-logo/pemetaan-dan-sig/kabupaten-gorontalo-utara.png";
import LogoKabKlaten from "~/assets/ilustration/klien/client-logo/pemetaan-dan-sig/kabupaten-klaten.png";
import LogoKabKulonProgo from "~/assets/ilustration/klien/client-logo/pemetaan-dan-sig/kabupaten-kulonprogo.png";
import LogoKabSragen from "~/assets/ilustration/klien/client-logo/pemetaan-dan-sig/kabupaten-sragen.png";

/**
 * SIDE IMAGE
 *
 */
import SideImageAplikasiOne from "~/assets/ilustration/klien/side-image/aplikasi/aplikasi-1.JPG";
import SideImageAplikasiTwo from "~/assets/ilustration/klien/side-image/aplikasi/aplikasi-2.jpg";
import SideImagePemetaanDesaOne from "~/assets/ilustration/klien/side-image/pemetaan/pemetaan-1.jpg";
import SideImagePemetaanDesaTwo from "~/assets/ilustration/klien/side-image/pemetaan/pemetaan-2.jpg";
import SideImageSIGDesaOne from "~/assets/ilustration/klien/side-image/sig/sig-1.jpg";
import SideImageSIGDesaTwo from "~/assets/ilustration/klien/side-image/sig/sig-2.jpg";
import SideImageWebsiteDesaOne from "~/assets/ilustration/klien/side-image/website/website-1.jpg";
import SideImageWebsiteDesaTwo from "~/assets/ilustration/klien/side-image/website/website-2.jpg";
import SideImageMasterPlanDesaOne from "~/assets/ilustration/klien/side-image/master-plan/master-plan-1.jpg";

export const CLIENT_US_DATA = [
  {
    number: "01",
    title: "Aplikasi (393 Desa)",
    client: [
      {
        image: LogoDilan,
        title: "kab. Klaten",
        desc: "391 Desa",
      },
      {
        image: LogoDepputi,
        title: "kab. Gorontalo Utara",
        desc: "1 Desa",
      },
    ],
    childCarousel: [
      {
        image: SideImageAplikasiOne,
        title: "Aplikasi 1",
      },
      {
        image: SideImageAplikasiTwo,
        title: "Aplikasi 2",
      },
    ],
  },
  // {
  //   number: "01",
  //   title: "Aplikasi (393 Desa)",
  //   client: [
  //     {
  //       image: LogoDesaCerdas,
  //       title: "kab. Sidoarjo",
  //       desc: "1 Desa",
  //     },
  //   ],
  //   childCarousel: [
  //     {
  //       image: SideImageAplikasiTwo,
  //       title: "Aplikasi 2",
  //     },
  //     {
  //       image: SideImageAplikasiOne,
  //       title: "Aplikasi 1",
  //     },
  //   ],
  // },
  {
    number: "02",
    title: "Pemetaan Desa (27 Desa)",
    client: [
      {
        image: LogoKabKlaten,
        title: "kab. Klaten",
        desc: "19 Desa",
      },
      {
        image: LogoKabGorontalo,
        title: "kab. Gorontalo Utara",
        desc: "1 Desa",
      },
    ],
    childCarousel: [
      {
        image: SideImagePemetaanDesaOne,
        title: "Pemetaan Desa 1",
      },
      {
        image: SideImagePemetaanDesaTwo,
        title: "Pemetaan Desa 2",
      },
    ],
  },
  {
    number: "02",
    title: "Pemetaan Desa (27 Desa)",
    client: [
      {
        image: LogoKabKulonProgo,
        title: "kab. Kulon Progo",
        desc: "1 Desa",
      },
      {
        image: LogoKabSragen,
        title: "kab. Sragen",
        desc: "1 Desa",
      },
    ],
    childCarousel: [
      {
        image: SideImagePemetaanDesaTwo,
        title: "Pemetaan Desa 2",
      },
      {
        image: SideImagePemetaanDesaOne,
        title: "Pemetaan Desa 1",
      },
    ],
  },
  {
    number: "03",
    title: "Sistem Informasi Geospasial (27 Desa)",
    client: [
      {
        image: LogoKabKlaten,
        title: "kab. Klaten",
        desc: "19 Desa",
      },
      {
        image: LogoKabGorontalo,
        title: "kab. Gorontalo Utara",
        desc: "1 Desa",
      },
    ],
    childCarousel: [
      {
        image: SideImageSIGDesaOne,
        title: "SIG 1",
      },
      {
        image: SideImageSIGDesaTwo,
        title: "SIG 2",
      },
    ],
  },
  {
    number: "03",
    title: "Sistem Informasi Geospasial (27 Desa)",
    client: [
      {
        image: LogoKabKulonProgo,
        title: "kab. Kulon Progo",
        desc: "1 Desa",
      },
      {
        image: LogoKabSragen,
        title: "kab. Sragen",
        desc: "1 Desa",
      },
    ],
    childCarousel: [
      {
        image: SideImageSIGDesaTwo,
        title: "SIG 2",
      },
      {
        image: SideImageSIGDesaOne,
        title: "SIG 1",
      },
    ],
  },
  {
    number: "04",
    title: "Website Desa (393 Desa)",
    client: [
      {
        image: LogoDilan,
        title: "kab. Klaten",
        desc: "391 Desa",
      },
      {
        image: LogoDepputi,
        title: "kab. Gorontalo Utara",
        desc: "1 Desa",
      },
    ],
    childCarousel: [
      {
        image: SideImageWebsiteDesaOne,
        title: "Website 1",
      },
      {
        image: SideImageWebsiteDesaTwo,
        title: "Website 2",
      },
    ],
  },
  // {
  //   number: "04",
  //   title: "Website Desa (393 Desa)",
  //   client: [
  //     {
  //       image: LogoDesaCerdas,
  //       title: "kab. Sidoarjo",
  //       desc: "1 Desa",
  //     },
  //   ],
  //   childCarousel: [
  //     {
  //       image: SideImageWebsiteDesaTwo,
  //       title: "Website 2",
  //     },
  //     {
  //       image: SideImageWebsiteDesaOne,
  //       title: "Website 1",
  //     },
  //   ],
  // },
  {
    number: "05",
    title: "Masterplan Desa (2 Desa)",
    client: [
      {
        image: LogoDesaPintar,
        title: "kab. Klaten",
        desc: "2 Desa",
      },
    ],
    childCarousel: [
      {
        image: SideImageMasterPlanDesaOne,
        title: "Masterplan 1",
      },
    ],
  },
];
