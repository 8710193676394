import {
  Box,
  Container,
  Typography,
  Stack,
  Button,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import EmailImage from "~/assets/ilustration/kontak/email.png";
import LocationImage from "~/assets/ilustration/kontak/location-mobile.png";
import NuraniSuciImage from "~/assets/ilustration/kontak/nurani.png";
import ArbhitaImage from "~/assets/ilustration/kontak/arbhita.png";
import TitleSection from "~/views/home/desktop/components/title-section";

const ButtonEmail = styled(Button)({
  width: "52px",
  height: "52px",
  borderRadius: "50%",
});

const ButtonLocation = styled(Button)({
  width: "60px",
  height: "60px",
  borderRadius: "50%",
});

const ButtonChat = styled(Button)({
  width: "101px",
  height: "52px",
  borderRadius: "50px",
  fontSize: "1rem",
  fontWeight: 600,
  letterSpacing: ".1px",
  boxShadow: "none",
  textTransform: "uppercase",
});

const StackContact = ({ children, title, desc, isChat, href }) => {
  return (
    <Stack direction="row" spacing={5}>
      <Box width="50px">{children}</Box>

      <Stack spacing={1}>
        <Typography color="secondary.dark" fontWeight={400} fontSize="0.875rem">
          {title}
        </Typography>

        <Typography
          color="secondary.dark"
          fontWeight={600}
          fontSize="1rem"
          letterSpacing=".3px"
        >
          {desc}
        </Typography>
      </Stack>
      {isChat && (
        <ButtonChat
          color="primary"
          component="a"
          href={href ?? "#"}
          variant="contained"
          style={{ color: "white" }}
        >
          chat
        </ButtonChat>
      )}
    </Stack>
  );
};

const ContactUsMobile = () => {
  return (
    <Box mt="10rem" height="739px" id="kontak">
      <Container>
        <TitleSection>
          Kontak
          <Box mt="1rem">
            <Typography fontWeight="400">
              Konsultasi mudah dari hp anda sekarang
            </Typography>
          </Box>
        </TitleSection>

        <Stack ml="1rem" justifyContent="center" mt="4rem" spacing={3}>
          <Stack direction="column" spacing={4}>
            <StackContact title="Email" desc="sandbox.juara@gmail.com">
              <ButtonEmail>
                <img src={EmailImage} width="62px" height="auto" alt="email" />
              </ButtonEmail>
            </StackContact>

            <StackContact
              title="Nurani Suci"
              desc="081225495050"
              isChat
              href="https://api.whatsapp.com/send?phone=6281225495050&text=Terima kasih, mohon informasi terkait Produk Desa Pintar"
            >
              <img
                src={NuraniSuciImage}
                alt="Nurani Suci"
                width="75px"
                height="auto"
              />
            </StackContact>

            <StackContact
              title="Arbhita"
              desc="085727665081"
              isChat
              href="https://api.whatsapp.com/send?phone=6285727665081&text=Terima kasih, mohon informasi terkait Produk Desa Pintar"
            >
              <img
                src={ArbhitaImage}
                alt="Arbhita"
                width="75px"
                height="auto"
              />
            </StackContact>

            <Box
              target="_blank"
              component="a"
              style={{ textDecoration: "none" }}
              href="https://www.google.co.id/maps/place/Informa+Studios/@-7.6967694,110.6100309,16.16z/data=!4m12!1m6!3m5!1s0x2e7a436660a9a18d:0xf1280b8a877c275a!2sInforma+Studios!8m2!3d-7.696488!4d110.6108641!3m4!1s0x2e7a436660a9a18d:0xf1280b8a877c275a!8m2!3d-7.696488!4d110.6108641"
            >
              <StackContact
                title="Desa Pintar Office"
                desc="66 Teratai Street, Bareng Lor, Klaten, Indonesia"
              >
                <ButtonLocation>
                  <img
                    src={LocationImage}
                    width="62px"
                    height="auto"
                    alt="email"
                  />
                </ButtonLocation>
              </StackContact>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default ContactUsMobile;
