import { Grid, Typography, Button, Box, Container } from "@mui/material";
import { styled } from "@mui/system";
import { useStylesHero } from "./styles/_hero";

const TitleHero = ({ color, children }) => {
  return (
    <Typography
      fontSize="4.375rem"
      letterSpacing="1.5px"
      lineHeight="85px"
      color={color}
      fontFamily={`'Playfair Display', serif`}
    >
      {children}
    </Typography>
  );
};

const ButtonHero = styled(Button)({
  boxShadow: "8px 14px 48px rgba(34, 184, 167, 0.25)",
  borderRadius: "50px",
  color: "white",
  width: "178px",
  height: "52px",
  fontWeight: "bold",
  fontSize: ".9rem",
  textTransform: "uppercase",
});

const HeroDesktop = ({ onOpenDialog }) => {
  const classes = useStylesHero();
  return (
    <Container>
      <Grid container className={classes.rootHero} spacing={3}>
        <Grid item lg={6}>
          <Box>
            <TitleHero color="secondary.dark">Inovasi Desa</TitleHero>
            <TitleHero color="primary">Membangun</TitleHero>
            <TitleHero color="secondary.dark">Indonesia</TitleHero>
          </Box>
          <Typography
            color="secondary.dark"
            fontSize="1rem"
            marginTop="2rem"
            fontWeight="light"
            marginBottom="2rem"
          >
            Cara mudah untuk mengatur sistem informasi desa meliputi sistem
            administrasi desa dan pelayanan publik yang terintegrasi dengan
            infrastruktur informasi geospasial
          </Typography>

          <ButtonHero
            color="primary"
            variant="contained"
            onClick={onOpenDialog}
          >
            coba aplikasi
          </ButtonHero>
        </Grid>
        <Grid item className={classes.heroRight} lg={5} />
      </Grid>
    </Container>
  );
};

export default HeroDesktop;
