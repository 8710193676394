import React, { useEffect, useState } from "react";
import { Fab, styled, Slide } from "@mui/material";
import NavigationIcon from "@mui/icons-material/ArrowUpward";

const CustomFab = styled(Fab)({
  position: "fixed",
  right: 30,
  bottom: 20,
  borderRadius: "10px",
  boxShadow: "none",
});

const Backtop = () => {
  const [open, setOpen] = useState(false);

  const handleTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const listenScrollEvent = () => {
    if (window.scrollY > 190) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <Slide in={open} direction="left">
      <CustomFab size="small" onClick={handleTop} style={{ zIndex: "5" }}>
        <NavigationIcon color="secondary" />
      </CustomFab>
    </Slide>
  );
};

export default Backtop;
