export const pallete = {
  primary: {
    main: "#5DC3B2",
    light: "#C4F9E1",
    dark: "#1D6771",
  },
  secondary: {
    main: "#465170",
    dark: "#1F2F59",
    light: "#E6EAF3",
  },
  warning: {
    main: "#FF7A00",
    dark: "#932F00",
    light: "#FFD999",
  },
};
