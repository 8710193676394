import {
  Box,
  Typography,
  Stack,
  Container,
  Grid,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import Carousel from "react-material-ui-carousel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useStylesCarousel } from "./styles/_client-us";
import TitleSection from "~/views/home/mobile/components/title-section";
import { CLIENT_US_DATA_MOBILE } from "~/constants/client-us-mobile";

const PrevButton = styled(IconButton)({
  width: "50px",
  height: "50px",
  position: "relative",
  left: "65vw",
  backgroundColor: "#E6EAF3",
  "&:hover": {
    backgroundColor: "#FF7A00",
    color: "white",
    opacity: 0.6,
  },
});

const NextButton = styled(IconButton)({
  width: "50px",
  height: "50px",
  backgroundColor: "#E6EAF3",
  "&:hover": {
    backgroundColor: "#FF7A00",
    color: "white",
    opacity: 0.6,
  },
});

const CarouselImage = styled("img")({
  padding: "10px",
  height: "70px",
  objectFit: "cover",
});

const CustomSideImage = styled("img")({
  width: "100%",
  objectFit: "cover",
  height: "300px",
  borderRadius: "15px",
});

const ClientUsMobile = () => {
  const classes = useStylesCarousel();
  return (
    <Box mt="5rem" height="739px">
      <Container>
        <TitleSection>
          Klien Kami
          <Box mt="1.5rem">
            <Typography fontWeight="400">
              Beberapa klien kami baik dari Pemkab
            </Typography>
            <Typography fontWeight="400">maupun Desa</Typography>
          </Box>
        </TitleSection>

        <Box mt="3.5rem">
          <Carousel
            swipe={false}
            autoPlay={false}
            className={classes.carouselRoot}
            fullHeightHover={false}
            navButtonsAlwaysVisible
            navButtonsWrapperProps={{
              style: {
                top: "-15px",
                paddingTop: "1rem",
              },
            }}
            indicatorContainerProps={{
              style: {
                bottom: "80px",
                position: "absolute",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: "#465170",
              },
            }}
            indicatorIconButtonProps={{
              style: {
                color: "#E6EAF3",
              },
            }}
            NavButton={({ onClick, next, prev }) => (
              <>
                {prev && (
                  <PrevButton onClick={onClick}>
                    <ArrowBackIcon fontSize="small" />
                  </PrevButton>
                )}

                {next && (
                  <NextButton onClick={onClick}>
                    <ArrowForwardIcon fontSize="small" />
                  </NextButton>
                )}
              </>
            )}
          >
            {CLIENT_US_DATA_MOBILE.map((data) => (
              <Stack spacing={4}>
                <Stack
                  direction="row"
                  position="relative"
                  top=".8rem"
                  mb="1rem"
                >
                  <Typography
                    fontSize="1.3rem"
                    fontWeight={600}
                    width="70%"
                    lineHeight="30px"
                    color="secondary.dark"
                    fontFamily={`'Playfair Display', serif`}
                  >
                    {data.title}
                  </Typography>
                </Stack>

                <Grid sm={12} lg={5}>
                  <Carousel
                    indicators={false}
                    navButtonsAlwaysInvisible={true}
                    swipe={false}
                    fullHeightHover={false}
                    autoPlay={true}
                    duration={300}
                    className={classes.carouselRootChild}
                  >
                    {data.childCarousel.map((elem) => (
                      <CustomSideImage src={elem.image} alt={elem.title} />
                    ))}
                  </Carousel>
                </Grid>
                <Grid>
                  {data.client.map((isClient) => (
                    <Grid
                      item
                      sm={12}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      spacing={3}
                      mb="1rem"
                    >
                      <Box>
                        <CarouselImage
                          src={isClient.image}
                          alt={isClient.title}
                        />
                      </Box>
                      <Stack spacing={1}>
                        <Typography
                          fontSize="1rem"
                          style={{ textTransform: "uppercase" }}
                        >
                          {isClient.title}
                        </Typography>
                        <Typography fontSize=".8rem" color="secondary.main">
                          {isClient.desc}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            ))}
          </Carousel>
        </Box>
      </Container>
    </Box>
  );
};

export default ClientUsMobile;
