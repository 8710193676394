import { Container, Box } from "@mui/material";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <>
      <Header />

      <Box component="main">{children}</Box>

      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
