import { forwardRef, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Stack,
  TextField,
  styled,
  IconButton,
  Slide,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useForm,Controller } from "react-hook-form";
import emailjs from "emailjs-com";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import AsyncSelect from "~/components/async-select";
import { useDialogTryApp } from "~/views/home/desktop/views/styles/_dialog-try-app";

const CustomSubmitButton = styled(LoadingButton)({
  width: "100%",
  height: "60px",
  borderRadius: "50px",
  backgroundColor: "#5DC3B2",
  color: "white",
  fontSize: "1rem",
  fontWeight: 600,
  textTransform: "uppercase",
  marginTop: "3rem !important",
  boxShadow: "8px 14px 48px rgba(34, 184, 167, 0.25)",
  "&:hover": {
    backgroundColor: "#5DC3B2",
  },
});

const CustomTextField = styled(TextField)({
  "& .MuiInput-root": {
    fontSize: "1.125rem",
    fontWeight: 600,
    padding: ".15rem",
    "&:hover": {
      "& .MuiInput-underline:after": {
        border: "1px solid #D1DAEB",
      },
    },
  },
  "& label": {
    color: "#465170",
    fontWeight: "400",
  },
  "& label.Mui-focused": {
    color: "#465170",
    fontWeight: "400",
  },
  "& .MuiInput-underline:after": {
    border: "1px solid #D1DAEB",
  },
});

const SlideTransition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogForm = ({ onClose, open }) => {
  const classes = useDialogTryApp();
  const refFrom = useRef(null);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  
  const {
    register,
    handleSubmit,
    control,
    reset: resetForm,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    provinsiID: null,
    kabKotaID: null,
    kecamatanID: null,
  });

  const handlePostData = () => {
    setLoading(true);

    emailjs
      .sendForm(
        "service_4h74vgg",
        "template_uywcyrf",
        refFrom.current,
        "user_fkDLeX6hQxPBonCHfky5D"
      )
      .then(() => {
        enqueueSnackbar("Permohonan sukses terikirim, silakan cek email Anda", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        resetForm();
        onClose();
      })
      .finally(() => setLoading(false));
  };

  const getProvinsi = async (values) => {
    const { data: req } = await fetch(
      `https://api-development.istudios.id/v1/provinsi?filter{nama_provinsi.icontains}=${values}`
    ).then((res) => res.json());

    return req;
  };

  const getKabupaten = async (values) => {
    const { data: req } = await fetch(
      `https://api-development.istudios.id/v1/kabkota?provinsi=${state.provinsiID}&filter{nama_kab_kota.icontains}=${values}`
    ).then((res) => res.json());

    return req;
  };

  const getKecamatan = async (values) => {
    const { data: req } = await fetch(
      `https://api-development.istudios.id/v1/kecamatan?kabkota=${state.kabKotaID}&filter{nama_kecamatan.icontains}=${values}`
    ).then((res) => res.json());

    return req;
  };

  const getDesa = async (values) => {
    const { data: req } = await fetch(
      `https://api-development.istudios.id/v1/desa?kecamatan=${state.kecamatanID}&filter{nama_desa.icontains}=${values}`
    ).then((res) => res.json());

    return req;
  };

  return (
    <Dialog
      fullScreen
      onClose={() => {
        resetForm();
        onClose();
      }}
      open={open}
      TransitionComponent={SlideTransition}
      scroll="body"
    >
      <Box className={classes.dialogSubstract} />

      <IconButton
        aria-label="close"
        onClick={onClose}
        className={classes.dialogCloseButton}
      >
        <CloseIcon style={{ color: "white" }} />
      </IconButton>

      <DialogContent className={classes.dialogBodyMobile}>
        <Stack spacing={2}>
          <Typography
            fontSize="3rem"
            fontWeight="600"
            lineHeight="64px"
            style={{ color: "#091535" }}
            fontFamily={`'Playfair Display', serif`}
          >
            Desa Pintar
          </Typography>

          <Typography
            fontSize="1rem"
            fontWeight="400"
            lineHeight="1.7rem"
            color="secondary.main"
          >
            Apabila anda ingin mencoba demo Aplikasi Desa Pintar, silakan
            mengisi formulir di bawah ini :
          </Typography>
        </Stack>

        <Box
          mt="2rem"
          autoComplete="off"
          position="relative"
          ref={refFrom}
          onSubmit={handleSubmit(handlePostData)}
          component="form"
        >
          <Stack spacing={3}>
            <CustomTextField
              label="Nama"
              type="text"
              variant="standard"
              {...register("nama", { required: true })}
              error={errors?.nama}
              helperText={errors?.nama && "Harap masukkan nama !"}
            />
            <CustomTextField
              variant="standard"
              type="email"
              label="Email"
              {...register("email", { required: true })}
              error={errors?.email}
              helperText={errors?.email && "Harap masukkan email !"}
            />

            <CustomTextField
              variant="standard"
              type="number"
              label="Nomor HP"
              {...register("no_hp", { required: true })}
              error={errors?.no_hp}
              helperText={errors?.no_hp && "Harap masukkan nomor hp !"}
            />

            <Controller
              name="provinsi"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <AsyncSelect
                  label="Provinsi"
                  name="provinsi"
                  loadOptions={getProvinsi}
                  optionLabel="nama_provinsi"
                  errors={errors?.provinsi}
                  helperText={errors?.provinsi && "Harap masukkan provinsi !"}
                  onChange={(e, val) => {
                    onChange(val.nama_provinsi);
                    setState((prevState) => ({
                      ...prevState,
                      provinsiID: val.id,
                    }));
                  }}
                />
              )}
            />

            {state.provinsiID && (
              <Controller
                name="kabupaten"
                control={control}
                rules={{ required: true }}
                key={!!state.provinsiID ? state.provinsiID : null}
                render={({ field: { onChange } }) => (
                  <AsyncSelect
                    label="Kabupaten"
                    loadOptions={getKabupaten}
                    name="kabupaten"
                    optionLabel="nama_kab_kota"
                    errors={errors?.kabupaten}
                    helperText={
                      errors?.kabupaten && "Harap masukkan kabupaten !"
                    }
                    onChange={(e, val) => {
                      onChange(val.nama_kab_kota);
                      setState((prevState) => ({
                        ...prevState,
                        kabKotaID: val.id,
                      }));
                    }}
                  />
                )}
              />
            )}

            {state.kabKotaID && (
              <Controller
                name="kecamatan"
                control={control}
                key={!!state.kabKotaID ? state.kabKotaID : null}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <AsyncSelect
                    label="Kecamatan"
                    name="kecamatan"
                    loadOptions={getKecamatan}
                    optionLabel="nama_kecamatan"
                    errors={errors?.kecamatan}
                    helperText={
                      errors?.kecamatan && "Harap masukkan kecamatan !"
                    }
                    onChange={(e, val) => {
                      onChange(val.nama_kecamatan);
                      setState((prevState) => ({
                        ...prevState,
                        kecamatanID: val.id,
                      }));
                    }}
                  />
                )}
              />
            )}
            {state.kecamatanID && (
              <Controller
                name="desa"
                control={control}
                key={!!state.kecamatanID ? state.kecamatanID : null}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <AsyncSelect
                    label="Desa"
                    name="desa"
                    loadOptions={getDesa}
                    optionLabel="nama_desa"
                    errors={errors?.desa}
                    helperText={errors?.kecamatan && "Harap masukkan desa !"}
                    onChange={(e, val) => onChange(val.nama_desa)}
                  />
                )}
              />
            )}

            <CustomSubmitButton
              color="primary"
              type="submit"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={30} style={{ color: "white" }} />
              }
            >
              Kirim Permohonan
            </CustomSubmitButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DialogForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default DialogForm;
