export const typography = {
  subtitle1: {
    fontFamily: ["Playfair Display", "serif"].join(","),
    fontWeight: 400,
  },
  body1: {
    fontFamily: ["Poppins", "serif"].join(","),
    fontWeight: 600,
    "@media (max-width: 678px)": {
      fontFamily: 500,
    },
  },
};
