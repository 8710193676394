import { makeStyles, createStyles } from "@mui/styles";
import SubtractIlustration from "~/assets/png/subtract.png";
import ExcludeIlustration from "~/assets/png/exclude.png";

export const useStylesProduk = makeStyles((theme) =>
  createStyles({
    rootProduk: {},
    produkContainer: {
      marginTop: "10rem !important",
    },
    subtractProduk: {
      backgroundImage: `url(${SubtractIlustration})`,
      position: "absolute",
      width: "100%",
      height: "478.29px",
      top: "105%",
      zIndex: 10,
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.up("xl")]: {
        backgroundSize: "cover",
        height: "700px",
        top: "90%",
      },
    },
    excludeProduk: {
      backgroundImage: `url(${ExcludeIlustration})`,
      position: "absolute",
      width: "150px",
      height: "619px",
      right: 0,
      bottom: "-240%",
      backgroundRepeat: "no-repeat",
    },
  })
);
