import React, { useState } from "react";
import { Box } from "@mui/system";
import {
  IconButton,
  ListItemText,
  ListItem,
  List,
  Dialog,
  Slide,
  Link,
} from "@mui/material";
import { useStyles } from "../styles/_header";
import Logo from "~/assets/png/desapintar.png";
import HamburgerIcon from "../icons/humberger";
import CloseIcon from "@mui/icons-material/Close";
import { NAV_PATH } from "~/constants/nav";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Header() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.box} display="flex" justifyContent="space-between">
      <Box>
        <img className={classes.logo} src={Logo} alt="Logo" />
      </Box>
      <Box>
        <IconButton onClick={handleClickOpen}>
          <HamburgerIcon />
        </IconButton>
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          spacing={0}
          px={3}
          py={2}
        >
          <Box>
            <img width="123px" height="26px" src={Logo} alt="Logo" />
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {NAV_PATH.map((item, i) => {
            const uniqKey = `${JSON.stringify(item)}-${i}`;
            return (
              <Link
                key={uniqKey}
                href={item.path}
                style={{ color: "black", textDecoration: "none" }}
              >
                <ListItem button onClick={handleClose}>
                  <ListItemText primary={item.name} />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Dialog>
    </Box>
  );
}
