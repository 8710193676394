import { Box, Container, Link } from "@mui/material";
import { useStylesHeader } from "./styles/_header";
import { NAV_PATH } from "~/constants/nav";
import Logo from "~/assets/svg/logo.svg";

const Header = () => {
  const classes = useStylesHeader();
  return (
    <Box height="80px" component="header">
      <Container>
        <Box display="flex" pt="32px" pb="20px" justifyContent="space-between">
          <img className={classes.logo} src={Logo} alt="Logo DesaPintar" />

          <Box>
            {NAV_PATH.map((nav, i) => {
              const uniqKey = `${JSON.stringify(nav)}-${i}`;

              return (
                <Link
                  key={uniqKey}
                  className={classes.linkHeader}
                  href={nav.path}
                >
                  {nav.name}
                </Link>
              );
            })}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
