import { useState } from "react";
import HeroDesktop from "./components/hero";
import Layout from "./components/layout";
import AlurDesktop from "./views/alur";
import ClientUsDesktop from "./views/client-us";
import ContactUsDesktop from "./views/contact";
import DialogTryApp from "./views/dialog-try-app";
import ProdukDesktop from "./views/produk";

const DesktopDevices = () => {
  const [openDialogTryApp, setOpenDialogTryApp] = useState(false);

  return (
    <Layout>
      <HeroDesktop onOpenDialog={() => setOpenDialogTryApp(true)} />
      <ProdukDesktop />
      <AlurDesktop />
      <ClientUsDesktop />
      <ContactUsDesktop />
      <DialogTryApp
        open={openDialogTryApp}
        onClose={() => setOpenDialogTryApp(false)}
      />
    </Layout>
  );
};

export default DesktopDevices;
