import { makeStyles, createStyles } from "@mui/styles";
import BackgroundFooter from "~/assets/ilustration/footer/mask.png";

export const useStylesFooter = makeStyles(() =>
  createStyles({
    footer: {
      position: "absolute",
      width: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${BackgroundFooter})`,
    },
    boxFooter: {
      marginTop: "15rem",
      position: "relative",
    },
    boxFooterTop: {
      position: "relative",
      top: "100px",
    },
    divider: {
      border: "1px solid rgba(255, 255, 255, 0.3)",
      marginBottom: "1.5rem",
    },
  })
);
