import { useState } from "react";
import Layouts from "./layout";
import AlurMobile from "./alur";
import ClientUsMobile from "./client-us";
import ContactUsMobile from "./contact";
import HeroMobile from "./hero";
import ProdukMobile from "./produk";
import DialogForm from "./view/dialog-form";

const MobileDevices = () => {
  const [openDialogTryApp, setOpenDialogTryApp] = useState(false);
  return (
    <Layouts>
      <HeroMobile onOpenDialog={() => setOpenDialogTryApp(true)} />
      <ProdukMobile />
      <AlurMobile />
      <ClientUsMobile />
      <ContactUsMobile />
      <DialogForm
        open={openDialogTryApp}
        onClose={() => setOpenDialogTryApp(false)}
      />
    </Layouts>
  );
};

export default MobileDevices;
