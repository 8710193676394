export const NAV_PATH = [
  {
    name: "Beranda",
    path: "/",
  },
  {
    name: "Produk",
    path: "#produk",
  },
  {
    name: "Kontak",
    path: "#kontak",
  },
];
