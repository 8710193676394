import { Box, Typography, Container } from "@mui/material";
import { useStyles } from "./styles/_alur";
import PemetaanDesa from "~/assets/ilustration/alur/alur-1.png";
import ValidasiData from "~/assets/ilustration/alur/alur-2.png";
import PengumpulanData from "~/assets/ilustration/alur/alur-3.png";
import PengolahanData from "~/assets/ilustration/alur/alur-4.png";
import ValidasiDanPemanfaatan from "~/assets/ilustration/alur/alur-5.png";
import Step1 from "~/assets/ilustration/alur/step-1.png";
import Step2 from "~/assets/ilustration/alur/step-2.png";
import Step3 from "~/assets/ilustration/alur/step-3.png";
import Step4 from "~/assets/ilustration/alur/step-4.png";
import Step5 from "~/assets/ilustration/alur/step-5.png";
import TitleSection from "./components/title-section";

const Alur = () => {
  const classes = useStyles();

  const steps = [
    {
      ilustrasi: PemetaanDesa,
      title: "Pemetaan Desa Menggunakan Drone",
      step: Step1,
    },
    {
      ilustrasi: ValidasiData,
      title: "Validasi Data dan Pembuatan Peta",
      step: Step2,
    },
    {
      ilustrasi: PengumpulanData,
      title: "Pengumpulan Data Masyarakat",
      step: Step3,
    },
    {
      ilustrasi: PengolahanData,
      title: "Pengolahan Data Menjadi Informasi",
      step: Step4,
    },
    {
      ilustrasi: ValidasiDanPemanfaatan,
      title: "Visualisasi dan Pemanfaatan Informasi",
      step: Step5,
    },
  ];
  return (
    <Container>
      <Box display="flex" justifyContent="center" paddingY={8}>
        <TitleSection>Alur</TitleSection>
      </Box>
      <Box display="flex" flexDirection="column" textAlign="center">
        {steps.map((el) => (
          <Box paddingBottom={3}>
            <Box>
              <img width="206px" src={el.ilustrasi} />
            </Box>
            <Box className={classes.boxStep}>
              <img width="60px" src={el.step} />
            </Box>
            <Box
              className={classes.text}
              paddingX={7}
              marginX={5}
              height="50px"
            >
              <Typography>{el.title}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default Alur;
