import { makeStyles } from "@mui/styles";
import Background from "~/assets/ilustration/produk/background.png";

export const useStyles = makeStyles({
  background: {
    backgroundImage: `url(${Background})`,
    width: "100%",
    height: "300px",
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    left: 0,
    right: 0,
  },
  headerText: {
    fontSize: "32px",
    fontWeight: 700,
    fontFamily: `"Playfair Display", serif`,

  },
});
