const InstagramIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_564:619)">
        <path
          d="M12 8C9.79443 8 8 9.79443 8 12C8 14.2056 9.79443 16 12 16C14.2056 16 16 14.2056 16 12C16 9.79443 14.2057 8 12 8Z"
          fill="white"
        />
        <path
          d="M21.5817 2.41827C20.0222 0.858765 17.9568 0 15.7657 0H8.23425C6.04321 0 3.9776 0.858765 2.41827 2.41827C0.858765 3.9776 0 6.04321 0 8.23425V15.7656C0 17.9568 0.858765 20.0224 2.41827 21.5817C3.9776 23.1412 6.04321 24 8.23425 24H15.7657C17.9568 24 20.0224 23.1412 21.5817 21.5817C23.1411 20.0224 24 17.9568 24 15.7656V8.23425C24 6.04321 23.1411 3.9776 21.5817 2.41827ZM12 18.3516C8.49774 18.3516 5.64844 15.5023 5.64844 12C5.64844 8.49774 8.49774 5.64844 12 5.64844C15.5023 5.64844 18.3516 8.49774 18.3516 12C18.3516 15.5023 15.5023 18.3516 12 18.3516ZM19.0364 5.95807C18.9056 6.08881 18.7242 6.16388 18.5392 6.16388C18.3541 6.16388 18.173 6.08881 18.0423 5.95807C17.911 5.82715 17.8361 5.64587 17.8361 5.46075C17.8361 5.27563 17.911 5.09418 18.0423 4.96344C18.173 4.83252 18.3539 4.75763 18.5392 4.75763C18.7242 4.75763 18.9056 4.83252 19.0364 4.96344C19.1673 5.09418 19.2424 5.27563 19.2424 5.46075C19.2424 5.64569 19.1673 5.82715 19.0364 5.95807Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_564:619">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstagramIcon;
