import { Box, Container, Grid, Typography, Stack, Button } from "@mui/material";
import { styled } from "@mui/system";
import EmailImage from "~/assets/ilustration/kontak/email.png";
import NuraniSuciImage from "~/assets/ilustration/kontak/nurani.png";
import ArbhitaImage from "~/assets/ilustration/kontak/arbhita.png";
import MapImage from "~/assets/ilustration/kontak/map.png";
import PopUpMap from "../components/popup-map";

const ButtonEmail = styled(Button)({
  backgroundImage: `url(${EmailImage})`,
  width: "62px",
  height: "62px",
  borderRadius: "50%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
});

const ButtonChat = styled(Button)({
  width: "101px",
  height: "52px",
  borderRadius: "50px",
  fontSize: "1rem",
  fontWeight: 600,
  letterSpacing: ".1px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#5DC3B2",
    color: "white",
  },
});

const CustomMap = styled("iframe")({
  width: "600px",
  height: "551px",
  border: "0",
  borderRadius: "20px",
});

const ContactStack = ({ children, title, desc, isChat, href }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={4}>
      <Box width="70px">{children}</Box>

      <Stack spacing={1}>
        <Typography fontSize="1rem" color="secondary.dark" fontWeight="400">
          {title}
        </Typography>

        <Typography
          fontSize="1.1rem"
          color="secondary.dark"
          fontWeight="600"
          letterSpacing=".3px"
        >
          {desc}
        </Typography>
      </Stack>

      {isChat && (
        <ButtonChat
          color="primary"
          href={href ?? "#"}
          target="blank"
          component="a"
          variant="outlined"
        >
          CHAT
        </ButtonChat>
      )}
    </Stack>
  );
};

const ContactUsDesktop = () => {
  return (
    <Box id="kontak" h="auto" mt="12rem">
      <Container>
        <Grid container justifyContent="center" alignItems="start">
          <Grid item sm={12} md={6}>
            <CustomMap
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.876206164428!2d110.6086238154721!3d-7.696432178354227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2s6P4G8J36%2BC8!5e0!3m2!1sid!2sid!4v1636188347431!5m2!1sid!2sid"
              allowFullScreen
              loading="lazy"
            />
            <PopUpMap href="https://www.google.co.id/maps/place/Informa+Studios/@-7.6967694,110.6100309,16.16z/data=!4m12!1m6!3m5!1s0x2e7a436660a9a18d:0xf1280b8a877c275a!2sInforma+Studios!8m2!3d-7.696488!4d110.6108641!3m4!1s0x2e7a436660a9a18d:0xf1280b8a877c275a!8m2!3d-7.696488!4d110.6108641" />
          </Grid>

          <Grid item position="relative" bottom="7px" padding="4.5rem" sm={12} md={6}>
            {/* HEADER */}
            <Box>
              <Typography
                color="secondary.dark"
                fontSize="2.5rem"
                fontWeight="700"
                fontStyle="normal"
                lineHeight="64px"
                textAlign="left"
                zIndex="9999"
                fontFamily={`'Playfair Display', serif`}
              >
                Kontak
                <Box mt=".5rem">
                  <Typography fontWeight="400">
                    Konsultasi mudah dari hp anda sekarang
                  </Typography>
                </Box>
                <Box
                  width="78px"
                  height="3px"
                  position="relative"
                  top="1rem"
                  backgroundColor="#FF7A00"
                />
              </Typography>
            </Box>

            {/* SECTION */}

            <Stack mt="4rem" spacing={4}>
              <ContactStack title="Email" desc="sandbox.juara@gmail.com">
                <ButtonEmail href="mailto:sandbox.juara@gmail.com" />
              </ContactStack>

              <ContactStack
                title="Nurani Suci"
                desc="081225495050"
                isChat
                href="https://api.whatsapp.com/send?phone=6281225495050&text=Terima kasih, mohon informasi terkait Produk Desa Pintar"
              >
                <img
                  src={NuraniSuciImage}
                  alt="Nurani Suci"
                  style={{ width: "75px", height: "60px", objectFit: "cover" }}
                />
              </ContactStack>

              <ContactStack
                title="Arbhita"
                desc="085727665081"
                isChat
                href="https://api.whatsapp.com/send?phone=6285727665081&text=Terima kasih, mohon informasi terkait Produk Desa Pintar"
              >
                <img
                  src={ArbhitaImage}
                  alt="Arbhita"
                  style={{ width: "75px", height: "60px", objectFit: "cover" }}
                />
              </ContactStack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactUsDesktop;
