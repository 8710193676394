import { StrictMode } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { Theme } from "./theme";
import { storeConfiguration } from "./redux/";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const store = storeConfiguration();

render(
  <StrictMode>
    <Provider store={store}>
      <Theme>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </Theme>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
reportWebVitals();
