import {
  Container,
  Box,
  Typography,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useStylesAlur } from "./styles/_alur";
import TitleSection from "../components/title-section";
import AlurBoxOne from "~/assets/ilustration/alur/alur-box-1.png";
import AlurBoxTwo from "~/assets/ilustration/alur/alur-box-2.png";
import AlurBoxThree from "~/assets/ilustration/alur/alur-box-3.png";
import AlurBoxFour from "~/assets/ilustration/alur/alur-box-4.png";
import AlurBoxFive from "~/assets/ilustration/alur/alur-box-5.png";
import IlustrationAlurOne from "~/assets/ilustration/alur/alur-1.png";
import IlustrationAlurTwo from "~/assets/ilustration/alur/alur-2.png";
import IlustrationAlurThree from "~/assets/ilustration/alur/alur-3.png";
import IlustrationAlurFour from "~/assets/ilustration/alur/alur-4.png";
import IlustrationAlurFive from "~/assets/ilustration/alur/alur-5.png";
import LineRowImage from "~/assets/png/line-row.png";
import LineRowSetImage from "~/assets/png/line-row-set.png";
import LineColumnImage from "~/assets/png/line-column.png";
import LineColumnSetImage from "~/assets/png/line-column-set.png";

const AlurDesktop = () => {
  const classes = useStylesAlur();
  const isDesktopLarge = useMediaQuery("1");
  return (
    <Box height="auto" mt="14rem">
      <Box className={classes.subtractAlur} />

      <Container>
        <TitleSection>Alur</TitleSection>

        <Box display="flex" flexDirection="column" marginTop="10rem">
          <Grid container>
            {/* ALUR ONE */}
            <Stack item lg={8} spacing={3} component={Grid}>
              <Stack direction="row" alignItems="center">
                <Stack direction="row" alignItems="center" spacing={3}>
                  <img
                    width="300px"
                    height="300px"
                    src={IlustrationAlurOne}
                    alt="Pemetaan Desa Menggunakan Drone"
                  />

                  <img
                    width="70px"
                    height="70px"
                    src={AlurBoxOne}
                    alt="Pemetaan Desa Menggunakan Drone"
                  />
                </Stack>

                <img src={LineRowImage} alt="linerow" />
              </Stack>

              <Typography
                width="300px"
                textAlign="center"
                fontSize="1.25rem"
                color="secondary.dark"
              >
                Pemetaan Desa Menggunakan Drone
              </Typography>
            </Stack>

            {/* ALUR TWO */}

            <Stack item lg={4} spacing={3} component={Grid}>
              <Stack direction="column" alignItems="center">
                <Stack direction="column" alignItems="center" spacing={3}>
                  <img
                    width="300px"
                    height="300px"
                    src={IlustrationAlurTwo}
                    alt="Validasi Data dan Pembuatan Peta"
                  />

                  <Typography
                    width="300px"
                    textAlign="center"
                    fontSize="1.25rem"
                    color="secondary.dark"
                  >
                    Validasi Data dan Pembuatan Peta
                  </Typography>

                  <img
                    width="70px"
                    height="70px"
                    src={AlurBoxTwo}
                    alt="Validasi Data dan Pembuatan Peta"
                  />
                </Stack>

                <img src={LineColumnImage} alt="linecolumn" />
              </Stack>
            </Stack>
          </Grid>

          {/* ALUR FIVE */}

          <Stack
            direction="column"
            alignItems="center"
            position="absolute"
            marginTop="24.5rem"
            marginLeft="8.5rem"
            w="100%"
          >
            <Stack direction="column" alignItems="center" spacing={3}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box>
                  <img src={LineRowSetImage} />
                </Box>
                <img
                  width="300px"
                  height="300px"
                  src={IlustrationAlurFive}
                  alt="Visualisasi dan Pemanfaatan Informasi"
                />

                <Box>
                  <img
                    width="70px"
                    height="70px"
                    src={AlurBoxFive}
                    alt="Visualisasi dan Pemanfaatan Informasi"
                  />
                </Box>
              </Stack>

              <Box position="relative" left="90px">
                <Typography
                  width="300px"
                  textAlign="center"
                  fontSize="1.25rem"
                  color="secondary.dark"
                >
                  Visualisasi dan
                  <br />
                  Pemanfaatan Informasi
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Grid container>
            {/* ALUR FOUR */}

            <Stack
              item
              position="relative"
              bottom="300px"
              lg={8}
              spacing={3}
              component={Grid}
            >
              <Stack direction="column">
                <Box w="10%" position="relative" left="135px">
                  <img src={LineColumnSetImage} alt="linecolumnset" />
                </Box>
                <Box
                  width="70px"
                  height="70px"
                  left="105px"
                  position="relative"
                  component="img"
                  src={AlurBoxFour}
                  alt="Pengolahan Data Menjadi Informasi"
                />
              </Stack>

              <Stack direction="row" alignItems="center">
                <Stack direction="row" alignItems="center">
                  <Box
                    width="300px"
                    height="300px"
                    component="img"
                    src={IlustrationAlurFour}
                    style={{ objectFit: "contain" }}
                    alt="Pengolahan Data Menjadi Informasi"
                  />

                  <img src={LineRowImage} alt="linerow" />
                </Stack>

                <img
                  width="70px"
                  height="70px"
                  src={AlurBoxThree}
                  alt="Pengumpulan Data Masyarakat"
                />
              </Stack>

              <Typography
                width="300px"
                textAlign="center"
                fontSize="1.25rem"
                color="secondary.dark"
              >
                Pengolahan Data
                <br />
                Menjadi Informasi
              </Typography>
            </Stack>

            {/* ALUR THREE */}

            <Stack
              item
              direction="column"
              alignItems="center"
              lg={4}
              spacing={3}
              component={Grid}
            >
              <Box
                width="300px"
                height="300px"
                component="img"
                src={IlustrationAlurThree}
                style={{ objectFit: "contain" }}
                alt="Pengumpulan Data Masyarakat"
              />

              <Typography
                width="300px"
                textAlign="center"
                fontSize="1.25rem"
                color="secondary.dark"
              >
                Pengumpulan Data Masyarakat
              </Typography>
            </Stack>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AlurDesktop;
