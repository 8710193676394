import {
  Typography,
  Container,
  Button,
  Box,
  Stack,
  styled,
  Link,
} from "@mui/material";
import { useStyles } from "../styles/_footer";
import GooglePlayImage from "~/assets/png/google-play.png";
import FacebookIcon from "~/icons/facebook";
import InstagramIcon from "~/icons/instagram";
import YoutubeIcon from "~/icons/youtube";
import TwitterIcon from "~/icons/twitter";

const ButtonGooglePlay = styled(Button)({
  width: "100%",
  height: "56px",
  display: "flex",
  justifyContent: "center",
  margin: "1.3rem auto !important",
  borderRadius: "1.719rem",
  backgroundColor: "#000000",
});

const Footer = () => {
  const classes = useStyles();

  const listAboutMe = [
    {
      label: "Galeri",
      href: "#",
    },
    {
      label: "Testimoni",
      href: "#",
    },
    {
      label: "Tim Kami",
      href: "#",
    },
  ];

  const listHelp = [
    {
      label: "Tanya Jawab",
      href: "#",
    },
    {
      label: "Kontak Kami",
      href: "#",
    },
  ];

  return (
    <Box component="footer">
      <Box className={classes.background}>
        <Box mt="10rem">
          <Container>
            <Stack spacing={3}>
              {/* <Typography
                textAlign="center"
                fontWeight={600}
                fontSize="1.3rem"
                lineHeight="30px"
                color="secondary.light"
                mb="1rem"
              >
                Dapatkan kemudahan sekarang?
              </Typography>

              <ButtonGooglePlay>
                <img src={GooglePlayImage} alt="googleplay" />
              </ButtonGooglePlay> */}

              <Stack
                position="relative"
                left="1rem"
                direction="row"
                spacing={10}
              >
                <Stack spacing={3}>
                  <Typography
                    color="secondary.light"
                    fontWeight={700}
                    fontSize="1.1rem"
                  >
                    Tentang Kami
                  </Typography>

                  {listAboutMe.map((item) => (
                    <Typography
                      // href={item.href}
                      color="secondary.light"
                      fontSize="15px"
                      lineHeight="15px"
                      fontWeight="normal"
                      component={Link}
                    >
                      {/* {item.label} */}
                    </Typography>
                  ))}
                </Stack>

                <Stack spacing={3}>
                  <Typography
                    color="secondary.light"
                    fontWeight={700}
                    fontSize="1.1rem"
                  >
                    Bantuan
                  </Typography>

                  {listHelp.map((item) => (
                    <Typography
                      // href={item.href}
                      color="secondary.light"
                      fontSize="15px"
                      lineHeight="15px"
                      fontWeight="normal"
                      // component={Link}
                    >
                      {item.label}
                    </Typography>
                  ))}
                </Stack>
              </Stack>

              <Box position="relative" px="1rem" pt="1rem">
                <Box className={classes.divider} />

                <Stack direction="row" spacing={4} justifyContent="center">
                  <Typography
                    color="secondary.light"
                    fontSize="15px"
                    lineHeight="15px"
                    fontWeight="normal"
                  >
                    ©️ 2022
                    <Typography
                      ml={1}
                      component={Link}
                      color="secondary.light"
                      fontSize="15px"
                      lineHeight="15px"
                      fontWeight="normal"
                      href="https://sandboxindonesia.id"
                    >
                      PT. Sandbox Indonesia Juara
                    </Typography>
                  </Typography>
                </Stack>

                <Stack
                  mt="1.594rem"
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Link
                    href="https://www.instagram.com/desapintar/"
                    target="_blank"
                  >
                    <InstagramIcon />
                  </Link>
                  <Link
                    href="https://facebook.com/Desa-Pintar-105681148599482"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </Link>
                  <Link
                    href="https://twitter.com/sandboxjuara_id"
                    target="_blank"
                  >
                    <TwitterIcon />
                  </Link>
                  <Link
                    href="https://www.youtube.com/channel/UC7V6_ts6SVCVt1LVftly5NQ"
                    target="_blank"
                  >
                    <YoutubeIcon />
                  </Link>
                </Stack>
              </Box>
            </Stack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
