import { makeStyles, createStyles } from "@mui/styles";
import SubstractImageDialog from "~/assets/png/subtract-dialog.png";

export const useDialogTryApp = makeStyles(() =>
  createStyles({
    dialogRoot: {
      width: "100%",
      height: "auto",
      borderRadius: "45px !important",
    },
    dialogSubstract: {
      backgroundImage: `url(${SubstractImageDialog})`,
      position: "absolute",
      width: "100%",
      height: "100%",
      right: 0,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    dialogCloseButton: {
      position: "relative",
      left: "88%",
      top: "20px",
    },
    dialogBody: {
      marginTop: "7rem",
      padding: "4rem !important",
    },
    dialogBodyMobile: {
      marginTop: "7rem",
      padding: "2rem !important",
    },
    dialogInput: {
      color: "#000",
      fontSize: "1.125rem !important",
      fontWeight: "normal",
      lineHeight: "24px",
    },
  })
);
