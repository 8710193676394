import { Fragment, useState, useEffect } from "react";
import {
  Autocomplete,
  CircularProgress,
  styled,
  TextField,
} from "@mui/material";
import { useDebounce } from "use-debounce";
import PropTypes from "prop-types";

const CustomTextField = styled(TextField)({
  "& .MuiInput-root": {
    fontSize: "1.125rem",
    fontWeight: 600,
    padding: ".15rem",
    "&:hover": {
      "& .MuiInput-underline:after": {
        border: "1px solid #D1DAEB",
      },
    },
  },
  "& label": {
    color: "#465170",
    fontWeight: "400",
  },
  "& label.Mui-focused": {
    color: "#465170",
    fontWeight: "400",
  },
  "& .MuiInput-underline:after": {
    border: "1px solid #D1DAEB",
  },
});

const AsyncSelect = ({
  optionLabel,
  loadOptions,
  label,
  errors,
  helperText,
  name,
  ...rest
}) => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [debounceSearch] = useDebounce(search, 200);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await loadOptions(debounceSearch);

        setOptions([...response]);
      } finally {
        setLoading(false);
      }
    })();
  }, [debounceSearch]);

  return (
    <Autocomplete
      open={open}
      getOptionLabel={(option) => option[optionLabel]}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      {...rest}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          onChange={({ target }) => setSearch(target.value)}
          variant="standard"
          type="text"
          name={name}
          label={label}
          error={errors}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

AsyncSelect.propTypes = {
  optionLabel: PropTypes.string.isRequired,
  loadOptions: PropTypes.func.isRequired,
  label: PropTypes.string,
  errors: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  name: PropTypes.string,
};

export default AsyncSelect;
