import HomePages from "./pages/home";
import Backtop from "~/components/backtotop";

const App = () => {
  return (
    <>
      <HomePages />
      <Backtop />
    </>
  );
};

export default App;
