import { makeStyles, createStyles } from "@mui/styles";

export const useStylesClientUs = makeStyles(() =>
  createStyles({
    rootClientUsDesktop: {
      paddingBottom: "7rem",
    },
    clientUsBox: {
      boxShadow: "0px 20px 30px rgba(17, 35, 82, 0.1)",
      borderRadius: "20px",
      marginTop: "7rem",
      height: "460px",
    },
    carouselRoot: {
      height: "100%",
    },
    carouselRootChild: {
      height: "100vh",
    },
  })
);
