import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  logo: {
    width: "122px",
  },
  box: {
    paddingLeft: "23px",
    paddingRight: "23px",
    paddingTop: "27px",
    paddingBottom: "27px",
  },
});
