import { makeStyles } from "@mui/styles";
import Mask from "~/assets/ilustration/footer/mask-mobile.png";

export const useStyles = makeStyles({
  background: {
    backgroundImage: `url(${Mask})`,
    width: "100vw",
    height: "700px",
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  divider: {
    border: "1px solid rgba(255, 255, 255, 0.3)",
    marginBottom: "1.5rem",
  },
});
