import { Box, Button, Container, Typography, Link, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { useStylesFooter } from "./styles/_footer";
import GooglePlayImage from "~/assets/ilustration/footer/google-play.png";
import FacebookIcon from "~/icons/facebook";
import InstagramIcon from "~/icons/instagram";
import YoutubeIcon from "~/icons/youtube";
import TwitterIcon from "~/icons/twitter";

// const ButtonGooglePlay = styled(Button)({
//   backgroundImage: `url(${GooglePlayImage})`,
//   width: "200px",
//   height: "60px",
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
// });

const LinkShare = styled(Link)({
  cursor: "pointer",
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
});

const Footer = () => {
  const classes = useStylesFooter();

  const listAboutMe = [
    {
      label: "Galeri",
      href: "#",
    },
    {
      label: "Testimoni",
      href: "#",
    },
    {
      label: "Tim Kami",
      href: "#",
    },
  ];

  const listHelp = [
    {
      label: "Tanya Jawab",
      href: "#",
    },
    {
      label: "Kontak Kami",
      href: "#",
    },
  ];

  return (
    <Box className={classes.footer} component="footer">
      <Container>
        <Box className={classes.boxFooter}>
          <Box className={classes.boxFooterTop} pb="7rem">
            <Stack display="flex" flexDirection="row">
              {/* <Box width="700px">
                <Typography
                  variant="body1"
                  color="white"
                  fontSize="22px"
                  width="50%"
                  fontWeight={600}
                  marginBottom="22px"
                >
                  Dapatkan kemudahan sekarang?
                </Typography>

                <ButtonGooglePlay />
              </Box> */}

              <Box display="flex" justifyContent="space-between" width="40%">
                <Box color="white">
                  <Typography
                    fontSize="18px"
                    lineHeight="24px"
                    fontWeight={500}
                  >
                    Tentang Kami
                  </Typography>
                  {listAboutMe.map((item) => (
                    <Box mb="24px" mt="24px">
                      <Typography
                        // href={item.href}
                        color="white"
                        fontSize="15px"
                        lineHeight="15px"
                        fontWeight="normal"
                        // component={Link}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box color="white">
                  <Typography
                    fontSize="18px"
                    lineHeight="24px"
                    fontWeight={500}
                  >
                    Bantuan
                  </Typography>
                  {listHelp.map((item) => (
                    <Box mb="24px" mt="24px">
                      <Typography
                        fontSize="15px"
                        color="white"
                        lineHeight="15px"
                        fontWeight="normal"
                        href={item.href}
                        // component={Link}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Stack>
          </Box>

          <Box pb="3rem">
            <Box display="block">
              <Box className={classes.divider} />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100% !important"
              >
                <Stack
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  zIndex="9999"
                >
                  <Typography
                    fontSize="15px"
                    color="white"
                    lineHeight="15px"
                    fontWeight="normal"
                  >
                    &copy; 2022&nbsp;
                    <Typography
                      href="https://sandboxindonesia.id/"
                      target="_blank"
                      fontSize="15px"
                      color="white"
                      lineHeight="15px"
                      fontWeight="normal"
                      as={Link}
                    >
                      PT. Sandbox Indonesia Juara
                    </Typography>
                  </Typography>
                </Stack>

                <Stack
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  zIndex="2"
                >
                  <LinkShare
                    target="_blank"
                    href="https://www.instagram.com/desapintar/"
                  >
                    <InstagramIcon />
                  </LinkShare>
                  <LinkShare
                    target="_blank"
                    href="https://facebook.com/Desa-Pintar-105681148599482"
                  >
                    <FacebookIcon />
                  </LinkShare>
                  <LinkShare
                    href="https://twitter.com/sandboxjuara_id"
                    target="_blank"
                  >
                    <TwitterIcon />
                  </LinkShare>
                  <LinkShare
                    href="https://www.youtube.com/channel/UC7V6_ts6SVCVt1LVftly5NQ"
                    target="_blank"
                  >
                    <YoutubeIcon />
                  </LinkShare>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
