import {
  Container,
  Box,
  Typography,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useStylesClientUs } from "./styles/_client-us";
import { CLIENT_US_DATA } from "~/constants/client-us";
import TitleSection from "../components/title-section";

const PrevButton = styled(IconButton)({
  width: "50px",
  height: "50px",
  position: "absolute",
  left: "500px",
  backgroundColor: "#E6EAF3",
});

const NextButton = styled(IconButton)({
  width: "50px",
  height: "50px",
  position: "absolute",
  right: "530px",
  backgroundColor: "#FF7A00",
  "&:hover": {
    backgroundColor: "#FF7A00",
    opacity: 0.6,
  },
});

const CarouselImage = styled("img")({
  height: "80px",
  padding: "8px",
});

const CustomSideImage = styled("img")({
  width: "100%",
  height: "460px",
  objectFit: "cover",
  borderTopRightRadius: "20px",
  borderBottomRightRadius: "20px",
});

const ClientUsDesktop = () => {
  const classes = useStylesClientUs();

  return (
    <Box className={classes.rootClientUsDesktop} h="auto">
      <Container>
        <TitleSection>
          Klien Kami
          <Box mt="1.5rem">
            <Typography fontWeight="400">
              Beberapa klien kami baik dari Pemkab
            </Typography>
            <Typography fontWeight="400">maupun Desa</Typography>
          </Box>
        </TitleSection>

        <Box className={classes.clientUsBox}>
          <Carousel
            swipe={false}
            autoPlay={false}
            className={classes.carouselRoot}
            fullHeightHover={false}
            animation="fade"
            navButtonsAlwaysVisible
            navButtonsWrapperProps={{
              style: {
                top: "120px",
              },
            }}
            indicatorContainerProps={{
              style: {
                position: "absolute",
                bottom: 0,
                textAlign: "left",
                padding: "3rem",
                zIndex: 9999999999999,
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: "#465170",
              },
            }}
            indicatorIconButtonProps={{
              style: {
                color: "#E6EAF3",
              },
            }}
            NavButton={({ onClick, next, prev }) => (
              <>
                {prev && (
                  <PrevButton onClick={onClick}>
                    <ArrowBackIcon fontSize="small" />
                  </PrevButton>
                )}

                {next && (
                  <NextButton onClick={onClick}>
                    <ArrowForwardIcon
                      fontSize="small"
                      style={{ color: "white" }}
                    />
                  </NextButton>
                )}
              </>
            )}
          >
            {CLIENT_US_DATA.map((item) => (
              <Grid container>
                <Grid item sm={12} lg={7} padding="3rem">
                  <Box>
                    <Typography fontSize="3.75rem" color="secondary.light">
                      {item.number}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        position="relative"
                        bottom="17px"
                        width="70%"
                        fontSize="1.875rem"
                        color="secondary.dark"
                        fontWeight="600"
                        fontFamily={`'Playfair Display', serif`}
                      >
                        {item.title}
                      </Typography>
                    </Box>

                    <Grid container position="relative" top="10px" spacing={3}>
                      {item.client.map((clientData) => (
                        <Grid item sm={12} lg={6}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <CarouselImage
                              src={clientData.image}
                              alt={clientData.title}
                            />

                            <Stack spacing={1}>
                              <Typography
                                fontSize=".9rem"
                                width="100%"
                                style={{ textTransform: "uppercase" }}
                              >
                                {clientData.title}
                              </Typography>
                              <Typography
                                fontSize=".8rem"
                                color="secondary.main"
                              >
                                {clientData.desc}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>

                <Grid sm={12} lg={5}>
                  <Carousel
                    indicators={false}
                    navButtonsAlwaysInvisible
                    swipe={false}
                    fullHeightHover={false}
                    autoPlay={true}
                    duration={300}
                    className={classes.carouselRootChild}
                  >
                    {item.childCarousel.map((elem) => (
                      <CustomSideImage src={elem.image} alt={elem.title} />
                    ))}
                  </Carousel>
                </Grid>
              </Grid>
            ))}
          </Carousel>
        </Box>
      </Container>
    </Box>
  );
};

export default ClientUsDesktop;
