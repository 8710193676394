import { Box } from "@mui/system";
import Header from "./header";
import Footer from "./footer";

function Layouts({ children }) {
  return (
    <>
      <Header />
      <Box component="main" mt="1.25rem">
        {children}
      </Box>
      <Footer />
    </>
  );
}

export default Layouts;
