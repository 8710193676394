import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useStylesPopupMap } from "./styles/_popup-map";
import LocationImage from "~/assets/ilustration/kontak/location.png";

const PopUpMap = ({ href }) => {
  const classes = useStylesPopupMap();
  return (
    <Box
      className={classes.rootPopup}
      href={href ?? "#"}
      target="_blank"
      component="a"
    >
      <Stack justifyContent="start" direction="row" spacing={3}>
        <img src={LocationImage} width="40px" height="40px" alt="Location" />

        <Stack spacing={1}>
          <Typography color="white" fontWeight="400">
            Desa Pintar Office
          </Typography>

          <Typography color="white" fontWeight="600" letterSpacing=".3px">
            66 Teratai Street, Bareng Lor, Klaten, Indonesia
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PopUpMap;
