import { Typography, Button, Box, Stack, Container } from "@mui/material";
import { styled } from "@mui/system";
import { useStyles } from "./styles/_hero";

const TitleHero = ({ color, children }) => {
  return (
    <Typography
      fontSize="2.813rem"
      letterSpacing="1.5px"
      lineHeight="55px"
      color={color}
      fontWeight={900}
      textAlign="center"
      fontFamily={`'Playfair Display', serif`}
      component="h1"
    >
      {children}
    </Typography>
  );
};

const ButtonHero = styled(Button)({
  boxShadow: "8px 14px 48px rgba(34, 184, 167, 0.25)",
  borderRadius: "50px",
  color: "white",
  width: "178px",
  height: "52px",
  fontWeight: "bold",
  fontSize: ".9rem",
  textTransform: "uppercase",
});

const Hero = ({ onOpenDialog }) => {
  const classes = useStyles();
  return (
    <Container>
      <Box height="739px">
        <Stack flexDirection="column" alignItems="center" spacing={3}>
          <Stack spacing={1}>
            <TitleHero color="secondary.dark">Inovasi Desa</TitleHero>
            <TitleHero color="primary">Membangun</TitleHero>
            <TitleHero color="secondary.dark">Indonesia</TitleHero>
          </Stack>

          <Typography
            fontWeight={400}
            fontSize="0.875rem"
            lineHeight="24px"
            textAlign="center"
            width="90%"
            display="block"
            mx="auto"
            color="secondary.dark"
          >
            Cara mudah untuk mengatur sistem informasi desa meliputi sistem
            administrasi desa dan pelayanan publik yang terintegrasi dengan
            infrastruktur informasi geospasial
          </Typography>

          <ButtonHero
            color="primary"
            variant="contained"
            onClick={onOpenDialog}
          >
            coba aplikasi
          </ButtonHero>
        </Stack>
        <Box className={classes.background} />
      </Box>
    </Container>
  );
};

export default Hero;
