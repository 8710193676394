import { useStyles } from "./styles/_produk";
import { Stack, Box, Container, Typography, Grid } from "@mui/material";
import { PRODUK_DATAS } from "~/constants/produk";
import TitleSection from "~/views/home/mobile/components/title-section";

const Produk = () => {
  const classes = useStyles();

  return (
    <Box id="produk">
      <Container>
        <Stack mt="11rem" height="100%" width="100%" direction="column">
          <Box
            display="flex"
            className={classes.background}
            justifyContent="center"
          />

          <Box position="relative" top="150px">
            <TitleSection>Produk</TitleSection>
          </Box>
          <Box
            marginTop="250px"
            display="flex"
            justifyContent="center"
            textAlign="center"
            flexDirection="row"
            paddingX={5}
            paddingY={3}
          >
            <Grid container spacing={5}>
              {PRODUK_DATAS?.map((el) => (
                <Grid item xs={6}>
                  <Stack alignItems="center" paddingY={3}>
                    <Box>
                      <img width="60px" height="60px" src={el?.image} />
                    </Box>
                    <Typography
                      color="secondary.dark"
                      fontSize="14px"
                      height="40px"
                      textAlign="center"
                      width="123px"
                    >
                      {el?.title}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Produk;
