import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const TitleSection = ({ children }) => {
  return (
    <Typography
      color="secondary.dark"
      fontSize="2.5rem"
      fontWeight="700"
      fontStyle="normal"
      lineHeight="64px"
      textAlign="center"
      zIndex="9999"
      fontFamily={`'Playfair Display', serif`}
    >
      {children}

      <Box
        width="78px"
        height="3px"
        position="relative"
        top="1rem"
        backgroundColor="#FF7A00"
        display="block"
        margin="0 auto"
      />
    </Typography>
  );
};

TitleSection.propTypes = {
  children: PropTypes.node,
};

export default TitleSection;
