import { makeStyles, createStyles } from "@mui/styles";

export const useStylesHeader = makeStyles((theme) =>
  createStyles({
    logo: {
      width: "auto",
      height: "140px",
      position: "relative",
      bottom: "50px",
    },
    linkHeader: {
      color: `${theme.palette.secondary.dark} !important`,
      textDecoration: "none !important",
      fontSize: "16px !important",
      fontWeight: `${500} !important`,
      fontStyle: "normal !important",
      marginRight: `50px !important`,
      transition: ".2s ease",
      "&:hover": {
        cursor: "pointer",
        color: `${theme.palette.primary.main} !important`,
      },
    },
  })
);
