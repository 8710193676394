import { makeStyles, createStyles } from "@mui/styles";
import SubtractIlustration from "~/assets/png/subtract-2.png";

export const useStylesAlur = makeStyles(() =>
  createStyles({
    subtractAlur: {
      backgroundImage: `url(${SubtractIlustration})`,
      position: "absolute",
      width: "100%",
      height: "365.3px",
      bottom: "-340%",
      backgroundRepeat: "no-repeat",
    },
  })
);
