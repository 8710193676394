import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useStylesProduk } from "./styles/_produk";
import TitleSection from "../components/title-section";
import { PRODUK_DATAS } from "~/constants/produk";

const useWidthProduk = (title, type) => {
  const exceptDescription = [
    "Etalase Desa dan Media Komunikasi",
    "Akses Layanan Publik, Akses Potensi Desa",
    "Kelola Potensi Pertanian dengan Profesional",
  ];

  if (type === "title") {
    if (title === "Pemetaan Desa") {
      return "150px";
    }

    if (title === "Command Center Desa") {
      return "250px";
    }

    return "200px";
  } else {
    if (title === "Konsultasi, Perencanaan, Desain dan Visualisasi") {
      return "250px";
    }

    if (title?.length > 38 && !exceptDescription.includes(title)) {
      return "300px";
    }

    if (
      exceptDescription.includes(title) &&
      title !== "Kelola Potensi Pertanian dengan Profesional"
    ) {
      return "190px";
    }

    if (title === "Kelola Potensi Pertanian dengan Profesional") {
      return "196px";
    }

    if (title === "Media Visual Promosi Desa") {
      return "150px";
    }

    return "200px";
  }
};

const ProdukCard = ({ image, title, description }) => {
  const widthTitle = useWidthProduk(title, "title");
  const widthDescription = useWidthProduk(description, "description");

  return (
    <Grid item lg={4} xs={12}>
      <Stack alignItems="center" mb="5rem" spacing={2}>
        <img
          width="100px"
          height="100px"
          src={image}
          alt={title}
          style={{ zIndex: 12 }}
        />
        <Typography
          color="secondary.dark"
          fontWeight="600"
          fontSize="1.25rem"
          lineHeight="30px"
          textAlign="center"
          width={widthTitle}
        >
          {title}
        </Typography>
        <Typography
          color="secondary.main"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="27.5px"
          textAlign="center"
          width={widthDescription}
        >
          {description}
        </Typography>
      </Stack>
    </Grid>
  );
};

const ProdukDesktop = () => {
  const classes = useStylesProduk();
  return (
    <Box id="produk" className={classes.rootProduk} height="auto" mt="14rem">
      <Box className={classes.subtractProduk} />
      <Container>
        <TitleSection>Produk</TitleSection>
        <Grid className={classes.produkContainer} container spacing={5}>
          {PRODUK_DATAS.map((produk, idx) => (
            <ProdukCard key={`${JSON.stringify(produk)}-${idx}`} {...produk} />
          ))}
        </Grid>
      </Container>

      <Box className={classes.excludeProduk} />
    </Box>
  );
};

export default ProdukDesktop;
