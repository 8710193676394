const FacebookIcon = () => {
  return (
    <svg
      width="13"
      height="24"
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99613 3.985H12.1871V0.169C11.8091 0.117 10.5091 0 8.99513 0C5.83613 0 3.67213 1.987 3.67213 5.639V9H0.186127V13.266H3.67213V24H7.94613V13.267H11.2911L11.8221 9.001H7.94513V6.062C7.94613 4.829 8.27813 3.985 9.99613 3.985Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
