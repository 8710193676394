import { useMediaQuery, useTheme } from "@mui/material";
import DesktopDevices from "~/views/home/desktop";
import MobileDevices from "~/views/home/mobile";

const HomePages = () => {
  const theme = useTheme();
  const mobileDevices = useMediaQuery(theme.breakpoints.down("lg"));

  if (mobileDevices) {
    return <MobileDevices />;
  }

  return <DesktopDevices />;
};

export default HomePages;
