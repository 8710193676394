import { makeStyles } from "@mui/styles";
import HeroImage from "~/assets/ilustration/hero/hero-1.png";

export const useStyles = makeStyles({
  background: {
    backgroundImage: `url(${HeroImage})`,
    width: "83%",
    height: "400px",
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    top: "550px",
    right: 0,
  },
});
