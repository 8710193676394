import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  text: {
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "1.5px",
    textAlign: "center",
    fontFamily: `"Playfair Display", serif`,
    fontWeight: 600,
    position:"relative",
    bottom:"20px",
  },
  boxStep: {
    margin: "0 auto",
    border: "3px solid #ffff",
    borderRadius: "100%",
    display: "flex",
    width: "40px",
    height: "40px",
    position: "relative",
    bottom: "45px",
    left: "50px",
    alignItems:"center",
    justifyContent:"center",
    color:"white",
  },
});
