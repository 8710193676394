const YoutubeIcon = () => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4686 2.92877L23.4986 3.12477C23.2086 2.09577 22.4256 1.30177 21.4306 1.01277L21.4096 1.00777C19.5386 0.49977 12.0096 0.49977 12.0096 0.49977C12.0096 0.49977 4.49959 0.48977 2.60959 1.00777C1.59559 1.30177 0.811592 2.09577 0.526592 3.10377L0.521592 3.12477C-0.177408 6.77577 -0.182408 11.1628 0.552592 15.0718L0.521592 14.8738C0.811592 15.9028 1.59459 16.6968 2.58959 16.9858L2.61059 16.9908C4.47959 17.4998 12.0106 17.4998 12.0106 17.4998C12.0106 17.4998 19.5196 17.4998 21.4106 16.9908C22.4256 16.6968 23.2096 15.9028 23.4946 14.8948L23.4996 14.8738C23.8176 13.1758 23.9996 11.2218 23.9996 9.22577C23.9996 9.15277 23.9996 9.07877 23.9986 9.00477C23.9996 8.93677 23.9996 8.85577 23.9996 8.77477C23.9996 6.77777 23.8176 4.82377 23.4686 2.92877ZM9.60759 12.6508V5.35777L15.8736 9.00977L9.60759 12.6508Z"
        fill="white"
      />
    </svg>
  );
};

export default YoutubeIcon;
