import { makeStyles, createStyles } from "@mui/styles";

export const useStylesPopupMap = makeStyles(() =>
  createStyles({
    rootPopup: {
      width: "480px",
      height: "122px",
      backgroundColor: "rgba(0, 0, 0, 0.38)",
      borderRadius: "25px",
      position: "relative",
      bottom: "200px",
      display: "block",
      margin: "0 auto",
      textDecoration: 'none',
      padding: "1.5rem",
      "&:hover": {
        opacity: 0.8,
        cursor: "pointer",
        transition: ".5s ease",
      },
    },
  })
);
